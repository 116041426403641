import { useMetricName } from 'utils/hooks/metrics/useMetricName'
import { useSubjectQuery } from 'utils/hooks/queries/useSubjectsQuery'
import { IndexMetric } from 'utils/types/metricsV2'

import * as Styles from './MetricName.styles'

interface MetricNameProps {
  metric: IndexMetric
  onNameClick?: () => void
  size?: string
  customStyle?: React.CSSProperties
}

const MetricName: React.FC<MetricNameProps> = ({
  metric,
  size,
  customStyle,
  onNameClick,
}) => {
  const { metricName } = useMetricName(metric)
  const { data: subject } = useSubjectQuery({
    subjectId: metric.subject.id,
    subjectType: metric.subject.type,
  })

  return metricName ? (
    <Styles.MetricName size={size} onClick={onNameClick} style={customStyle}>
      {metricName} ({subject?.name})
    </Styles.MetricName>
  ) : null
}

export default MetricName
